import Slider from './Slider';
import SliderContingutTercers from './Slider.contingut_tercers';
import SliderDirectes from './Slider.directes';
import SliderPastilles from './Slider.pastilles';

export default function Component(props) {
    const { format, tipus } = props;
    if (tipus === 'FAST_CHANNEL') {
        return <SliderDirectes {...props} isFastChannel={true} />;
    } else if (tipus === 'DIRECTES' || tipus === 'DIRECTES_FUTUR') {
        return <SliderDirectes {...props} />;
    } else if (format === 'CONT_TERCERS') {
        return <SliderContingutTercers {...props} />;
    } else {
        return format === 'PASTILLA' ? <SliderPastilles {...props} /> : <Slider {...props} />;
    }
}
