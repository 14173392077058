import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useProducte } from 'hooks/3cat/useProducte';
import Imatge from 'components/3cat/Imatge';
import Boto from 'components/3cat/ui/Boto';
import Icona from 'components/3cat/ui/Icona';
import __isEmpty from 'lodash/isEmpty';
import __isUndefined from 'lodash/isUndefined';
import clsx from 'clsx';
import styles from './slider.module.scss';

const Info = (props) => {
    const { element } = props;
    const { title, abstract, type } = element;

    return (
        <>
            {type && (
                <span className={styles.avantitol} data-testid={'slide-avantitol'}>
                    {type}
                </span>
            )}
            {!__isEmpty(title) && (
                <h3 className={styles.titol} data-testid={'slide-titol'}>
                    {title}
                </h3>
            )}
            {!__isEmpty(abstract) && (
                <div
                    className={styles.entradeta}
                    data-testid={'slide-entradeta'}
                    dangerouslySetInnerHTML={{ __html: abstract }}
                ></div>
            )}
        </>
    );
};

const ModalContingutTercers = (props) => {
    const { showModal, setShowModal, element } = props;
    const { isWebviewActive } = useProducte();
    const { title, abstract, images, button } = element;

    const closeModalHandler = (e) => {
        setShowModal(false);
    };

    if (!showModal) return <></>;

    return ReactDOM.createPortal(
        <div className={styles.maskModalContingutTercers} onClick={closeModalHandler}>
            <div className={styles.overlayModalContingutTercers} onClick={(e) => e.stopPropagation()}>
                <button aria-label='Tanca' className={styles.btn_tanca} onClick={closeModalHandler}>
                    <Icona className={styles.ico_tanca} inline={true} icona='tanca' />
                </button>
                <div className={styles.imatgeModalContingutTercers}>
                    <Imatge
                        className={styles.imatge}
                        src={images.modal_landscape}
                        alt={title}
                        priority={false}
                        layout='fill'
                        objectFit='cover'
                    />
                </div>
                <h2>{title}</h2>
                <h4>{abstract}</h4>
                <div className={styles.contentBotonsModalContingutTercers}>
                    {isWebviewActive ? (
                        <>
                            <Boto
                                text='Enllaç Android'
                                tag='a'
                                link={button.link.android}
                                className={styles.botoEnllacContingutTercers}
                                estil='none'
                                fons='fosc'
                                novaFinestra={true}
                            />
                            <Boto
                                text='Enllaç iOs'
                                tag='a'
                                link={button.link.iOs}
                                className={styles.botoEnllacContingutTercers}
                                fons='fosc'
                                estil='none'
                                novaFinestra={true}
                            />
                        </>
                    ) : (
                        <Boto
                            text='Enllaç web'
                            tag='a'
                            link={button.link.web}
                            className={styles.botoEnllacContingutTercers}
                            fons='fosc'
                            estil='none'
                            novaFinestra={true}
                        />
                    )}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default function SlideContingutTercers(props) {
    const { element, midaImatge } = props;
    const [showModal, setShowModal] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [divHover, setDivHover] = useState('');
    const { title, images } = element;

    const styleWrapper = clsx({
        [styles.wrapper]: true,
        [styles.wrapperContingutTercers]: true
    });

    useEffect(() => {
        setDivHover('');
        if (isHover) {
            setTimeout(() => {
                if (isHover) setDivHover(clsx({ [styles.itemHover]: true }));
            }, 200);
        }
    }, [isHover]);

    if (__isEmpty(title) && __isEmpty(images)) {
        return false;
    }

    const hoverHandler = (e) => {
        setIsHover(true);
    };
    const outHandler = () => {
        setIsHover(false);
    };

    return (
        <>
            <div
                className={`${styleWrapper} ${divHover}`}
                onMouseEnter={hoverHandler}
                onMouseLeave={outHandler}
                onClick={() => setShowModal(true)}
            >
                <div className={styles.textWrapper}>
                    <Info element={element} />
                </div>

                <div className={styles.imatgeWrapper}>
                    <Imatge
                        className={styles.imatge}
                        src={images.row_portrait}
                        tipus={midaImatge}
                        alt={title}
                        priority={false}
                        mostrarRecursTitol={midaImatge !== 'web_keyframe'}
                        layout='fill'
                        objectFit='cover'
                    />
                </div>
            </div>
            <ModalContingutTercers setShowModal={setShowModal} showModal={showModal} element={element} />
        </>
    );
}

SlideContingutTercers.defaultProps = {
    showLogoPlataforma: false
};
