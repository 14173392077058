import { useRef, useState } from 'react';
import { addSlideClass, getPropsSwiper, getVisibleSlides } from './funcions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { typesViewport, useViewport } from 'hooks/useViewport';
import { useProducte } from 'hooks/3cat/useProducte';
import Icona from 'components/3cat/ui/Icona';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import __isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import styles from './slider.module.scss';
import SlideContingutTercers from './Slide.contingut_tercers';

const _netejaTitolTira = (titol) => {
    if (!titol) return titol;
    return titol.replace(/\*\[([^\][]*)]\*/g, '').trim();
};

export default function SliderContingutTercers(props) {
    const { items, tipus, urlDadesByProducte, midaImatge, breakpoints, format } = props;

    const titol = _netejaTitolTira(props.titol);
    const { isWebviewActive } = useProducte();
    const navigationPrev = useRef(null);
    const navigationNext = useRef(null);
    const swiper = useRef(null);
    const [classPos, setClassPos] = useState('');
    const [swiperInit, setSwiperInit] = useState(false);
    const [swiperMoved, setSwiperMoved] = useState(false);
    const isAudio = false;
    const isPoster = true;
    const [visibleSlides, setVisibleSlides] = useState(0);
    const sliderId = 'id' + (titol + tipus + format).replace(/^[^a-z]+|[^\w:.-]+/gi, '');
    let resizeTimeOut;

    const { viewport } = useViewport();

    const stylesSwiper = clsx({
        [styles.innerSlider]: true,
        [styles.dreta]: classPos === 'openRight',
        [styles.esquerra]: classPos === 'openLeft'
    });

    const stylesModul = clsx({
        [styles.slider]: true,
        [styles.init]: swiperInit,
        [styles.noTitol]: __isEmpty(titol),
        [styles.webview]: isWebviewActive || viewport === typesViewport.VP_MOBILE
    });

    const stylesPoster = clsx({
        [styles.poster]: isPoster,
        [styles.quadrat]: isAudio
    });

    const _iterateItems = (items) => {
        if (!swiperMoved) {
            return !__isEmpty(items)
                ? Array.from(items)
                      .slice(0, visibleSlides)
                      .map((element, i) => _buildSlide(element, i))
                : null;
        } else {
            return !__isEmpty(items) ? Array.from(items).map((element, i) => _buildSlide(element, i)) : null;
        }
    };

    const _buildSlide = (element, i) => {
        return (
            <SwiperSlide
                key={element.id}
                onMouseEnter={(e) => setClassPos(addSlideClass(e, swiper))}
                virtualIndex={i}
                data-testid={`slider-contingut-tercers-item-${i}`}
            >
                <SlideContingutTercers
                    dataTestId={`slide-contingut-tercers-${titol.toLowerCase()}-${i}`}
                    element={element}
                    tipus={tipus}
                    midaImatge={midaImatge}
                    format={format}
                    isWebviewActive={isWebviewActive}
                />
            </SwiperSlide>
        );
    };

    const _renderSwiper = () => {
        const propsSwiper = breakpoints ? getPropsSwiper(breakpoints) : getPropsSwiper(midaImatge);

        return (
            <Swiper
                {...propsSwiper}
                ref={swiper}
                navigation={{
                    prevEl: navigationPrev.current,
                    nextEl: navigationNext.current
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrev.current;
                    swiper.params.navigation.nextEl = navigationNext.current;
                    setVisibleSlides(getVisibleSlides(propsSwiper));
                }}
                onAfterInit={() => {
                    setSwiperInit(true);
                }}
                modules={[Navigation, FreeMode]}
                className={`${stylesSwiper} ${stylesPoster}`}
                updateOnWindowResize={false}
                onSwiper={(swiper) => {
                    window.addEventListener('resize', function () {
                        clearTimeout(resizeTimeOut);
                        resizeTimeOut = setTimeout(() => {
                            setVisibleSlides(getVisibleSlides(propsSwiper));
                            swiper.update();
                        }, 100);
                    });
                }}
                onSlideChange={() => {
                    setSwiperMoved(true);
                }}
                data-testid={`tira-${tipus}-${format}`}
                data-haslink={true}
            >
                <button ref={navigationPrev} className={styles.anterior}>
                    <Icona icona='fletxa_simple_esquerra' alt='Anterior' isBlanc={false} mida={30} />
                </button>
                <button ref={navigationNext} className={styles.seguent}>
                    <Icona icona='fletxa_simple_dreta' alt='Següent' isBlanc={false} mida={30} className={styles.iconadreta} />
                </button>
                {_iterateItems(items)}
            </Swiper>
        );
    };

    return !__isEmpty(items) ? (
        <section className={stylesModul} aria-labelledby={sliderId}>
            <h2 className={styles.infoTitol} id={sliderId}>
                {titol}
            </h2>
            {_renderSwiper()}
        </section>
    ) : (
        <></>
    );
}

SliderContingutTercers.defaultProps = {
    titol: '',
    items: {},
    icona: '',
    midaImatge: 'web_posterpetit',
    mostraTitol: true,
    isFilter: false
};
